<template>
  <a-card :bodyStyle="{ padding: 0, paddingTop: '1px' }">
    <template slot="title">
      <filters :filters="filters" />
    </template>
    <report-table :items="items" :loading="loading" :users="users" />
  </a-card>
</template>

<script>
  import _ from 'lodash'
  import moment from 'moment'
  import { api, legacyApi } from '@/api'
  import { dateFormat } from '@/modules/report-by-months/config'
  import ReportTable from './ReportTable'
  import Filters from './filters/Filters'
  import { cleanObject, getQuery } from '@/modules/report-by-months/utils'

  export default {
    name: 'WorthReportView',
    components: {
      Filters,
      ReportTable,
    },
    data() {
      return {
        loading: false,
        filters: null,
        result: null,
        users: [],
        items: [],
      }
    },
    methods: {
      itemsFromResult() {
        if (!this.result) this.items = []
        else {
          let { departments, total } = this.result

          const transform = (item, type, parents = [], children, childKey, lazy = true) => {
            item.monthsByKey = _.keyBy(item.months, 'id')
            item.key = [type, ...parents.map(p => p.id), item.id].join('_')
            item.type = type

            if (type === 'task' && item.closedAt) {
              const closedAt = moment(item.closedAt)
              const key = closedAt.format('YYYY-MM')

              if (!item.monthsByKey[key]) return
              this.$set(item.monthsByKey[key], 'completed', true)
            }

            if (children) {
              children = _.orderBy(children, ['totals.money'], ['desc'])

              if (!lazy) item.children = children
              else {
                const [first, ...other] = children
                if (first) {
                  item.children = [first]
                  item.lazyChildren = other
                }
              }
            }

            return item
          }

          departments = departments.map(department => {
            const users = department.users.map(user => {
              const projects = user.projects.map(project => {
                const tasks = project.tasks.map(task => transform(task, 'task', [department, user, project]))
                return transform(project, 'project', [department, user], tasks, 'tasks')
              })
              return transform(user, 'user', [department], projects, 'projects')
            })
            return (department = transform(department, 'department', [], users, 'users', false))
          })

          this.items = [transform({ id: 'total', name: 'Итого', ...total }, 'total'), ..._.sortBy(departments, 'name')]
        }
      },
      async fetch() {
        try {
          this.loading = true
          const { data: result } = await api.get('/reports/cost-by-period', {
            params: cleanObject(this.filters),
          })
          this.result = result
          this.itemsFromResult()
        } catch (e) {
          console.error(e)
        } finally {
          this.loading = false
        }
      },
      async getUsers() {
        const {
          data: { items: users },
        } = await legacyApi.get('users')
        this.users = users
      },
      setDefaultFilters() {
        const { filters = {} } = getQuery()
        this.filters = {
          dateStart: filters.dateStart || moment().startOf('year').format(dateFormat),
          dateEnd: filters.dateEnd || moment().endOf('month').format(dateFormat),
          client: filters.client || null,
          project: filters.project || null,
          task: filters.task || null,
          persons: filters.persons || '',
        }
      },
    },
    async created() {
      this.setDefaultFilters()
      await this.fetch()
      await this.getUsers()
    },
    watch: {
      '$route.query': {
        async handler() {
          await this.fetch()
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  /deep/ {
    .ant-table-row-level-1 {
      $color: rgba(0, 0, 0, 0.01);
      background: $color;

      td {
        background: $color;
      }
    }

    .ant-table-row-level-2 {
      $color: rgba(0, 0, 0, 0.02);
      background: $color;

      td {
        background: $color;
      }
    }

    .ant-table-row-level-3 {
      $color: rgba(0, 0, 0, 0.03);
      background: $color;

      td {
        background: $color;
      }
    }
  }
</style>
