import qs from 'qs'
import _ from 'lodash'

export function getQuery() {
  return qs.parse(location.search, { ignoreQueryPrefix: true })
}

export function cleanObject(object) {
  object = _.omitBy(object, _.isNil)
  object = _.omitBy(object, v => _.isObject(v) && _.isEmpty(v))
  object = _.omitBy(object, v => v === '')

  return object
}

export function getLink(record) {
  const { type } = record
  if (type === 'project') return getProjectLink(record)
  else if (type === 'task') return getTaskLink(record)
  else return null
}

function getTaskLink(record) {
  const query = getFiltersQuery(
    {
      id: record.id,
      preset: 'allOpenedAndClosed',
      client: 'all',
    },
    'tasks'
  )
  return `/tasks?${query}`
}

function getProjectLink(record) {
  const query = getFiltersQuery(
    {
      id: record.id,
      preset: 'allActiveAndArchive',
    },
    'projects'
  )
  return `/projects?${query}`
}

function getFiltersQuery(filters, entity) {
  return qs.stringify({ filters: { [entity]: filters } })
}
