<template>
  <a-row :gutter="16">
    <date-filter :dateStart.sync="filters.dateStart" :dateEnd.sync="filters.dateEnd" />
    <persons-filter :value.sync="filters.persons" />
    <a-col :span="4">
      <a-form-item>
        <a-input placeholder="Клиент" v-model="filters.client" allowClear />
      </a-form-item>
    </a-col>
    <a-col :span="4">
      <a-form-item>
        <a-input placeholder="Проект" v-model="filters.project" allowClear />
      </a-form-item>
    </a-col>
    <a-col :span="4">
      <a-form-item>
        <a-input placeholder="Задача" v-model="filters.task" allowClear />
      </a-form-item>
    </a-col>
  </a-row>
</template>

<script>
  import _ from 'lodash'
  import qs from 'qs'
  import DateFilter from './DateFilter'
  import { getQuery, cleanObject } from '@/modules/report-by-months/utils'
  import PersonsFilter from '@/modules/report-by-months/components/filters/PersonsFilter'

  export default {
    name: 'Filters',
    components: { PersonsFilter, DateFilter },
    props: {
      filters: Object,
    },
    data() {
      return {
        timeout: null,
      }
    },
    watch: {
      filters: {
        deep: true,
        handler(filters) {
          filters = cleanObject(filters)

          let currentQuery = getQuery()
          let newQuery = _.omit(currentQuery, 'filters')

          if (Object.values(filters).length) {
            newQuery.filters = filters
          }

          if (this.timeout) clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            if (!_.isEqual(currentQuery, newQuery)) {
              this.$router.replace(`${this.$route.path}?${qs.stringify(newQuery)}`)
            }
          }, 500)
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  /deep/ {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
</style>
