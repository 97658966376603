<template>
  <a-col :span="5">
    <a-form-item label="Период" :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }">
      <a-range-picker
        :mode="['month', 'month']"
        :value="value"
        separator="-"
        format="MMM YYYY"
        :placeholder="['с', 'по']"
        @panelChange="onChange"
        :allowClear="false"
      />
    </a-form-item>
  </a-col>
</template>

<script>
  import moment from 'moment'
  import { dateFormat } from '@/modules/report-by-months/config'

  export default {
    name: 'DateFilter',
    props: {
      dateStart: String,
      dateEnd: String,
    },
    data() {
      return {
        value: [moment(), moment()],
      }
    },
    created() {
      this.value = [moment(this.dateStart, dateFormat), moment(this.dateEnd, dateFormat)]
    },
    methods: {
      onChange(value) {
        this.value = value
      },
    },
    watch: {
      value([start, end]) {
        this.$emit('update:dateStart', start ? start.startOf('month').format(dateFormat) : null)
        this.$emit('update:dateEnd', end ? end.endOf('month').format(dateFormat) : null)
      },
    },
  }
</script>

<style scoped></style>
