<template>
  <div class="name-wrapper" :style="{ width: `${getNameWidth(record)}px`, opacity }">
    <a-avatar
      v-if="record.type === 'user'"
      :src="getUserAvatar(record)"
      icon="user"
      :size="20"
      style="margin-right: 2px"
    />
    <a-tag v-if="client" :title="client" style="font-size: 9.5px; padding: 0 6px; margin-right: 2px">
      {{ client | slicer(20) }}
    </a-tag>
    <name-link tag="span" :link="getLink(record)" :title="record.name">
      {{ record.name }}
    </name-link>
  </div>
</template>

<script>
  import { getLink } from '../utils'
  import NameLink from './NameLink'

  export default {
    name: 'NameWrapper',
    components: {
      NameLink,
    },
    props: {
      users: Array,
      column: Object,
      record: Object,
    },
    computed: {
      type() {
        return this.record.type
      },
      client() {
        if (this.type === 'project') {
          return this.record.client || '-'
        } else return null
      },
      opacity() {
        if (['project', 'task'].includes(this.type) && !this.record.visible) return 0.6
        else return 1
      },
    },
    methods: {
      getUserAvatar(record) {
        const { photoLink } = this.users.find(({ id }) => id === record.id) || {}
        return photoLink
      },
      getNameWidth(record) {
        const { type } = record
        const { width } = this.column
        const paddingDelta = 16
        const expanderDelta = 17 + 8

        let delta = 0

        if (type === 'task') delta = 60
        else if (type === 'project') delta = 40
        else if (type === 'user') delta = 20
        else if (type === 'department') delta = 0

        return width - paddingDelta - expanderDelta - delta
      },
      getLink,
    },
  }
</script>

<style scoped>
  .name-wrapper {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }
</style>
