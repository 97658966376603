<template>
  <a-table
    v-if="show"
    :loading="loading"
    :columns="allColumns"
    :dataSource="items"
    :defaultExpandedRowKeys="defaultExpanded"
    :scroll="{ x: tableScrollX, y: workHeight - 50 }"
    :rowClassName="rowClassName"
    :rowKey="r => r.key"
    :pagination="false"
    :expandRowByClick="false"
    @expand="onExpand"
    size="small"
  >
    <name-wrapper slot="name" slot-scope="value, record" :record="record" :column="nameColumn" :users="users" />
    <div
      slot="totals"
      slot-scope="value, record, index"
      :class="{ 'stat-completed': value.completed }"
      style="font-size: 12px"
    >
      <template v-if="!value.time && !value.money"> - </template>
      <div v-else style="display: flex; align-items: center">
        <div style="flex: 1; text-align: right">
          {{ value.time | formatDuration(value.time > 60 * 60 ? 'h [ч]' : 'm [м]') }}
        </div>
        <a-divider type="vertical" />
        <div style="flex: 1; text-align: left">{{ value.money | formatNumber }} &#8381;</div>
      </div>
    </div>
  </a-table>
  <div v-else>
    <a-table :loading="true" />
  </div>
</template>

<script>
  import _ from 'lodash'
  import moment from 'moment'
  import { mapGetters } from 'vuex'
  import NameWrapper from './NameWrapper'

  const columnSize = 200

  export default {
    name: 'ReportTable',
    components: { NameWrapper },
    props: {
      loading: Boolean,
      items: Array,
      users: Array,
    },
    data() {
      return {
        show: false,
        defaultExpanded: [],
        columns: [
          {
            title: '',
            dataIndex: 'name',
            width: 450,
            fixed: 'left',
            scopedSlots: {
              customRender: 'name',
            },
          },
          {
            title: 'Всего',
            dataIndex: 'totals',
            width: columnSize,
            fixed: 'left',
            align: 'center',
            scopedSlots: {
              customRender: 'totals',
            },
          },
          {
            title: 'В среднем',
            dataIndex: 'avg',
            align: 'center',
            width: columnSize,
            scopedSlots: {
              customRender: 'totals',
            },
          },
        ],
      }
    },
    computed: {
      ...mapGetters(['workHeight']),
      nameColumn() {
        return this.columns.find(c => c.dataIndex === 'name')
      },
      allColumns() {
        return [
          ...this.columns,
          ...this.months.map(month => {
            const date = moment(month, 'YYYY-MM')

            return {
              title: date.format('MMM YYYY'),
              dataIndex: `monthsByKey.${month}`,
              align: 'center',
              width: columnSize,
              scopedSlots: {
                customRender: 'totals',
              },
            }
          }),
        ]
      },
      firstItem() {
        return this.items[0] || null
      },
      months() {
        if (!this.firstItem) return []
        const { months } = this.firstItem
        return months.map(({ id }) => id)
      },
      tableScrollX() {
        return _.sumBy(this.allColumns, 'width') + 100
      },
    },
    methods: {
      rowClassName(record) {
        const { key } = record
        if (key === 'total') return 'total-row'
      },
      onExpand(open, record) {
        const { lazyChildren = [] } = record

        if (open && lazyChildren.length) {
          record.children.push(...record.lazyChildren)
          record.lazyChildren = []
          this.$forceUpdate()
        }
      },
    },
    watch: {
      items: {
        immediate: true,
        async handler(departments) {
          this.defaultExpanded = departments.map(i => i.key)

          this.show = false
          await this.$nextTick()
          this.show = true
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  /deep/ {
    .ant-table {
      border: none;
      font-size: 0.95rem;

      .stat-completed {
        color: #52c41a;
        /*font-weight: 500;*/
      }
    }

    [data-row-key^='total'] {
      font-weight: 600;
    }

    [data-row-key^='department'] {
      font-weight: 500;
    }
  }
</style>
