<template>
  <a v-if="link" :href="link" target="_blank"><slot /></a>
  <component v-else :is="tag"><slot /></component>
</template>

<script>
  export default {
    name: 'NameLink',
    props: {
      link: String,
      tag: {
        type: String,
        default: 'span',
      },
    },
  }
</script>
