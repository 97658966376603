<template>
  <a-col :span="4">
    <a-form-item>
      <a-select
        placeholder="Сотрудники"
        allowClear
        showSearch
        v-model="internalValue"
        optionFilterProp="children"
        :maxTagCount="0"
        :filterOption="filterOption"
        mode="multiple"
        :loading="loading"
        :disabled="loading"
        style="width: 100%"
      >
        <div slot="maxTagPlaceholder">Выбрано: {{ internalValue.length }}<br /></div>
        <a-select-option v-for="i in items" :key="getKey(i)" :value="getKey(i)">
          <div style="display: flex; align-items: center" :style="{ marginLeft: isUser(i) ? '10px' : undefined }">
            <a-avatar v-if="isUser(i)" icon="user" :src="i.photoLink" :size="15" style="margin-right: 5px" />
            <span>{{ i.name }}</span>
          </div>
        </a-select-option>
      </a-select>
    </a-form-item>
  </a-col>
</template>

<script>
  import { legacyApi } from '@/api'

  export default {
    name: 'PersonsFilter',
    props: {
      value: String,
    },
    data() {
      return {
        loading: false,
        items: [],
        internalValue: [],
      }
    },
    methods: {
      filterOption(input, option) {
        return option.componentOptions.children[0].elm.innerText.toLowerCase().indexOf(input.toLowerCase()) >= 0
      },
      getKey(i) {
        return this.isUser(i) ? `user_${i.id}` : `department_${i.id}`
      },
      isUser(i) {
        return !!i.username
      },
      getDepartmentUsers(department) {
        return department.items.map(employee => employee.user)
      },
      async fetch() {
        try {
          this.loading = true
          const {
            data: { items: departments },
          } = await legacyApi.get('employees/list', {
            params: {
              'with[employees]': ['department', 'user', 'person', 'position', 'role_id'].join(),
            },
          })

          const items = []
          departments.forEach(department => {
            items.push(department, ...this.getDepartmentUsers(department))
          })
          this.items = items
        } catch (e) {
          console.error(e)
        } finally {
          this.loading = false
        }
      },
    },
    async created() {
      await this.fetch()
      this.internalValue = this.value ? this.value.split(',') : []
    },
    watch: {
      internalValue(value) {
        this.$emit('update:value', value.join())
      },
    },
  }
</script>
